import React, { useMemo, useCallback, useState } from "react";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TYPE_ACCESSY } from "../integrations/utils";
import { DateTimeCell, TextCell, LinkCell, ChipCell } from "../../components/tables/MaterialTableCells";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";

function CustomerPageExternalAccounts({ getCustomerExternalAccounts, deleteCustomerExternalAccount }) {
    const { customer, customerExternalAccountsPagination, isLoading } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const customerId = customer?.id;

    const [itemToDelete, setItemToDelete] = useState(null);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (customerId) {
                getCustomerExternalAccounts(customerId, pageIndex + 1, pageSize);
            }
        },
        [customerId, getCustomerExternalAccounts]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={customer?.id}
                        data={useMemo(() => customerExternalAccountsPagination.data, [
                            customerExternalAccountsPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={customerExternalAccountsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.LIST.HEADER.INTEGRATION" />
                                    ),
                                    accessor: "integration",
                                    Cell: (props) => {
                                        const integration = props.value;
                                        if (integration.type === TYPE_ACCESSY) {
                                            return LinkCell(
                                                `/integrations/accessy/${integration.id}`,
                                                integration.name
                                            );
                                        }
                                        return TextCell(integration.name);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.LIST.HEADER.EXTERNAL_ID" />
                                    ),
                                    accessor: "externalId",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === "finished") {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.STATUS.FINISHED" />
                                            );
                                        } else if (props.value === "pending") {
                                            return ChipCell(
                                                "warning",
                                                <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.STATUS.PENDING" />
                                            );
                                        } else if (props.value === "deleted") {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.STATUS.DELETED" />
                                            );
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "actions",
                                    textAlign: "center",
                                    Cell: ({ row }) => {
                                        return (
                                            <ListActionsButton
                                                menuItems={[
                                                    {
                                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.DELETE" />,
                                                        onClick: () => {
                                                            setItemToDelete(row.original);
                                                        },
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            <YesNoDialog
                title={<FormattedMessage id="CUSTOMER.EXTERNAL_ACCOUNTS.DELETE.TEXT" />}
                open={itemToDelete ? true : false}
                onOpenChanged={() => setItemToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteCustomerExternalAccount(customerId, itemToDelete.id, () => {
                            fetchData({ pageIndex: 0, pageSize: 10 });
                        });
                    }
                    setItemToDelete(null);
                }}
            />
        </Card>
    );
}
export default connect(null, { ...customerActions, ...errorActions })(CustomerPageExternalAccounts);
