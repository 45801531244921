import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";

export const CUSTOMERS_URL = "v1/admin/profiles/:id/customers";
export const CUSTOMER_DETAILS_URL = "v1/admin/customers/";
export const CUSTOMER_BOOKINGS_URL = "v1/admin/customers/:id/bookings";
export const CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_URL = "v1/admin/customers/:id/bookings/subscription-available";
export const CUSTOMER_BOOKING_RESOURCES_URL = "v1/admin/customers/:id/bookings/resources";
export const CUSTOMER_TRANSACTIONS_URL = "v1/admin/customers/:id/transactions";
export const CUSTOMER_SUBSCRIPTIONS_URL = "v1/admin/customers/:id/subscriptions";
export const CUSTOMER_VOUCHERS_URL = "v1/admin/customers/:id/vouchers";
export const CUSTOMER_MEMBERSHIPS_URL = "v1/admin/customers/:id/memberships";
export const CUSTOMER_USER_PROXIES_URL = "v1/admin/customers/:id/user-proxies";
export const CUSTOMER_EXTERNAL_ACCOUNTS_URL = "v1/admin/customers/:id/external-accounts";
export const CUSTOMER_DELETE_EXTERNAL_ACCOUNT_URL = "v1/admin/customers/:customerId/external-accounts/:entityId";
export const CUSTOMER_PAYMENT_METHODS_URL = "v1/admin/customers/:id/payment-methods";
export const CREATE_CUSTOMER_URL = "/v1/admin/profiles/:id/customers";

export function getCustomers(profileId, page, perPage, search, status) {
    let url =
        CUSTOMERS_URL.replace(":id", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "");

    return axios.get(getApiUrl() + url);
}

export function getCustomer(id) {
    return axios.get(getApiUrl() + CUSTOMER_DETAILS_URL + id);
}

export function addCustomer(id, properties) {
    const url = CREATE_CUSTOMER_URL.replace(":id", id);
    return axios.post(getApiUrl() + url, properties);
}

export function updateCustomer(id, properties) {
    return axios.put(getApiUrl() + CUSTOMER_DETAILS_URL + id, properties);
}

export function deleteCustomer(id) {
    return axios.delete(getApiUrl() + CUSTOMER_DETAILS_URL + id);
}

export function getCustomerBookings(id, page, perPage, search, status) {
    const url = `${CUSTOMER_BOOKINGS_URL.replace(":id", id)}?page=${page}&perPage=${perPage}${
        search ? "&search=" + search : ""
    }${status ? "&status=" + status : ""}`;
    return axios.get(getApiUrl() + url);
}

export function getCustomerSubscriptionAvailableBookings(id, page, perPage, search) {
    const url = `${CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_URL.replace(":id", id)}?page=${page}&perPage=${perPage}${
        search ? "&search=" + search : ""
    }`;
    return axios.get(getApiUrl() + url);
}

export function getCustomerBookingResources(id, page, perPage) {
    const url = `${CUSTOMER_BOOKING_RESOURCES_URL.replace(":id", id)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getCustomerTransactions(id, page, perPage, search, status, paymentType, paymentStatus) {
    const url =
        CUSTOMER_TRANSACTIONS_URL.replace(":id", id) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "") +
        (paymentType ? "&paymentType=" + paymentType : "") +
        (paymentStatus ? "&paymentStatus=" + paymentStatus : "");
    return axios.get(getApiUrl() + url);
}

export function getCustomerSubscriptions(id, page, perPage, search, status) {
    const url =
        CUSTOMER_SUBSCRIPTIONS_URL.replace(":id", id) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "");
    return axios.get(getApiUrl() + url);
}

export function getCustomerVouchers(id, page, perPage) {
    const url = CUSTOMER_VOUCHERS_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getCustomerMemberships(id, page, perPage) {
    const url = CUSTOMER_MEMBERSHIPS_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getCustomerUserProxies(id, page, perPage) {
    const url = CUSTOMER_USER_PROXIES_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getCustomerExternalAccounts(id, page, perPage) {
    const url = CUSTOMER_EXTERNAL_ACCOUNTS_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function deleteCustomerExternalAccount(customerId, entityId) {
    const url = CUSTOMER_DELETE_EXTERNAL_ACCOUNT_URL.replace(":customerId", customerId).replace(":entityId", entityId);
    return axios.delete(getApiUrl() + url);
}

export function getCustomerPaymentMethods(id, page, perPage, search, type) {
    const url =
        CUSTOMER_PAYMENT_METHODS_URL.replace(":id", id) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (type ? "&paymentMethod=" + type : "");
    return axios.get(getApiUrl() + url);
}
