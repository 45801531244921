import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as reportActions } from "../../../redux/reports/reportRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../components/SnackbarError";
import ReportCommonDetailsForm from "./ReportCommonDetailsForm";
import ReportFilterValuesForm from "./ReportFilterValuesForm";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as reportUtils from "./utils";
import moment from "moment";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    reportType: yup
        .object()
        .nullable()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

const useStyles = makeStyles((theme) => ({
    formContent: {
        marginTop: theme.spacing(4),
    },
    alertContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
}));

function ReportPageAdd({ addReport, clearReportState, clearDisplayError, clearErrorState, location, getReportCopy }) {
    const { profile } = useSelector((state) => state.auth);
    const { report, isLoading, reportToCopy } = useSelector((state) => state.reports);
    const { displayError } = useSelector((state) => state.errors);

    const [redirect, setRedirect] = useState(false);
    const [onSaveHasBeenClicked, setOnSaveHasBeenClicked] = useState(false);

    const classes = useStyles();
    const intl = useIntl();

    const queryParams = new URLSearchParams(location.search);
    const copyReportId = queryParams.get("copyReportId");

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit, setValue } = formMethods;

    useEffect(() => {
        return () => {
            clearReportState();
            clearErrorState();
        };
    }, [clearReportState, clearErrorState]);

    useEffect(() => {
        if (copyReportId) {
            getReportCopy(copyReportId);
        }
    }, [copyReportId, getReportCopy]);

    useEffect(() => {
        if (reportToCopy) {
            setValue("name", intl.formatMessage({ id: "REPORT.COPY.NEW_NAME" }, { reportName: reportToCopy.name }));
        }
    }, [reportToCopy, setValue, intl]);

    const onFormSubmit = (values) => {
        setOnSaveHasBeenClicked(true);

        const filterValues = { ...values };
        delete filterValues.name;
        delete filterValues.reportType;

        const data = {
            name: values.name,
            type: values.reportType.id,
        };

        if (data.type === reportUtils.REPORT_TYPE_TRANSACTIONS) {
            if (!filterValues.transactionCreatedStart || !filterValues.transactionCreatedEnd) {
                return;
            }

            filterValues.transactionCreatedStart = moment(filterValues.transactionCreatedStart).format();
            filterValues.transactionCreatedEnd = moment(filterValues.transactionCreatedEnd).format();

            if (filterValues.paymentTypes) {
                filterValues.paymentTypes = filterValues.paymentTypes.map((paymentType) => paymentType.id);
            }

            if (filterValues.paymentStatuses) {
                filterValues.paymentStatuses = filterValues.paymentStatuses.map((paymentStatus) => paymentStatus.id);
            }
        } else if (data.type === reportUtils.REPORT_TYPE_GROUP_ATTENDEES_COUNT) {
            if (!filterValues.courseStartTime || !filterValues.courseEndTime) {
                return;
            }

            filterValues.courseStartTime = moment(filterValues.courseStartTime).format();
            filterValues.courseEndTime = moment(filterValues.courseEndTime).format();
        } else if (data.type === reportUtils.REPORT_TYPE_GROUP_ATTENDEES) {
            if (!filterValues.courseStartTime || !filterValues.courseEndTime) {
                return;
            }

            filterValues.courseStartTime = moment(filterValues.courseStartTime).format();
            filterValues.courseEndTime = moment(filterValues.courseEndTime).format();
        } else if (data.type === reportUtils.REPORT_TYPE_GROUP_OCCASION_ATTENDEES) {
            if (!filterValues.entryStartTime || !filterValues.entryEndTime) {
                return;
            }

            filterValues.entryStartTime = moment(filterValues.entryStartTime).format();
            filterValues.entryEndTime = moment(filterValues.entryEndTime).format();
        } else if (data.type === reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS) {
            if (!filterValues.bookingStartTime) {
                return;
            }

            filterValues.bookingStartTime = moment(filterValues.bookingStartTime).format();
            filterValues.bookingEndTime = filterValues.bookingEndTime
                ? moment(filterValues.bookingEndTime).format()
                : null;
        } else if (data.type === reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED) {
            if (!filterValues.bookingStartTime) {
                return;
            }

            filterValues.bookingStartTime = moment(filterValues.bookingStartTime).format();
            filterValues.bookingEndTime = filterValues.bookingEndTime
                ? moment(filterValues.bookingEndTime).format()
                : null;
        } else if (data.type === reportUtils.REPORT_TYPE_RESOURCES) {
            if (filterValues.resourceTypes) {
                filterValues.resourceTypes = filterValues.resourceTypes.map((resourceType) => resourceType.id);
            }
        } else if (data.type === reportUtils.REPORT_TYPE_RESOURCE_APPOINTMENT_BOOKINGS) {
            if (!filterValues.bookingStartTime) {
                return;
            }

            filterValues.bookingStartTime = moment(filterValues.bookingStartTime).format();
            filterValues.bookingEndTime = filterValues.bookingEndTime
                ? moment(filterValues.bookingEndTime).format()
                : null;

            if (filterValues.resourceTypes) {
                filterValues.resourceTypes = filterValues.resourceTypes.map((resourceType) => resourceType.id);
            }
        } else if (data.type === reportUtils.REPORT_TYPE_SUBSCRIPTIONS) {
            if (filterValues.subscriptionStatuses) {
                filterValues.subscriptionStatuses = filterValues.subscriptionStatuses.map((status) => status.id);
            }
        }

        data.filterValues = JSON.stringify(filterValues);

        addReport(profile.id, data, () => {
            setRedirect(true);
        });
    };

    if (redirect && report) {
        return <Redirect to={`/reports/${report.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="REPORT.ADD.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="REPORT.ADD.DESCRIPTION" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body className="pt-0">
                    <FormProvider {...formMethods}>
                        <Form className={classes.formContent}>
                            <ReportCommonDetailsForm report={reportToCopy} />

                            <ReportFilterValuesForm
                                onSaveHasBeenClicked={onSaveHasBeenClicked}
                                showReportInfo={true}
                                report={reportToCopy}
                                isExistingReport={false}
                            />

                            <div style={{ marginTop: "50px" }}>
                                <Alert severity="info" className={classes.alertContainer}>
                                    <FormattedMessage id="REPORT.ADD.CREATE_INFO" />
                                </Alert>

                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="REPORT.ADD.CREATE_BUTTON" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...reportActions,
    ...errorActions,
})(ReportPageAdd);
