import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Col } from "react-bootstrap";
import { useForm, FormProvider, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { actions as serviceActions } from "../../../redux/services/serviceRedux";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { Switch } from "@material-ui/core";
import { PriceForm } from "../../components/price/PriceForm";
import DefaultAsyncTypeahead from "../../components/DefaultAsyncTypeahead";

const useStyles = makeStyles((theme) => ({
    formContent: {
        padding: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    booking: yup.object().when(["inheritFromBooking", "isCreatingItem"], {
        is: (inheritFromBooking, isCreatingItem) => {
            return inheritFromBooking && isCreatingItem;
        },
        then: (schema) => {
            return yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        },
        otherwise: (schema) => {
            return yup.object();
        },
    }),
    name: yup.string().when("inheritFromBooking", (inheritFromBooking) => {
        if (!inheritFromBooking) {
            return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        }

        return yup.string();
    }),
    quantity: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    price: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    vat: yup.object(),
    currency: yup.object(),
});

function SubscriptionItemModal({
    show,
    subscriptionId,
    customerId,
    subscriptionItem,
    addSubscriptionItem,
    updateSubscriptionItem,
    getServiceOptions,
    getCustomerSubscriptionAvailableBookings,
    onItemAdded,
    onItemUpdated,
    onCloseClicked,
}) {
    const { isUpdating } = useSelector((state) => state.subscriptions);
    const { profile } = useSelector((state) => state.auth);
    const { serviceOptions } = useSelector((state) => state.services);
    const { customerSubscriptionAvailableBookingsPagination } = useSelector((state) => state.customers);
    const profileId = profile.id;
    const classes = useStyles();
    const intl = useIntl();

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit, register, control, watch, setValue, errors } = formMethods;

    useEffect(() => {
        if (!serviceOptions) {
            getServiceOptions(profileId);
        }
    }, [profileId, getServiceOptions, serviceOptions]);

    useEffect(() => {
        if (!subscriptionItem && customerId) {
            getCustomerSubscriptionAvailableBookings(customerId, 1, 20);
        }
    }, [subscriptionItem, customerId, getCustomerSubscriptionAvailableBookings]);

    useEffect(() => {
        if (subscriptionItem) {
            setValue("inheritFromBooking", subscriptionItem.inheritFromBooking);
        }
    }, [subscriptionItem, setValue]);

    const onFormSubmit = (formValues) => {
        let requestData = {};
        requestData.inheritFromBooking = formValues.inheritFromBooking;

        if (!requestData.inheritFromBooking) {
            requestData.name = formValues.name;
            requestData.quantity = formValues.quantity;
            requestData.price = formValues.price;
            requestData.vatId = formValues.vat.id;
            requestData.currencyId = formValues.currency.id;
        }

        if (!subscriptionItem) {
            if (requestData.inheritFromBooking) {
                requestData.bookingId = formValues.booking.id;
            }

            addSubscriptionItem(subscriptionId, requestData, (item) => {
                onItemAdded(item);
            });
        } else {
            const originalItem = {};
            originalItem.inheritFromBooking = subscriptionItem.inheritFromBooking;

            if (requestData.inheritFromBooking) {
                // Make sure data differs so that all values are updated when not inheriting
                originalItem.name = subscriptionItem.name;
                originalItem.quantity = subscriptionItem.quantity;
                originalItem.price = subscriptionItem.price;
                originalItem.vatId = subscriptionItem.vat.id;
                originalItem.currencyId = subscriptionItem.currency.id;
            }

            updateSubscriptionItem(subscriptionItem.id, originalItem, requestData, (item) => {
                onItemUpdated(item);
            });
        }
    };

    const vat = watch("vat");
    if (!vat?.id && serviceOptions?.vats) {
        register("vat");

        if (!subscriptionItem) {
            const defaultVat = serviceOptions.vats.find((x) => x.isDefault);
            setValue("vat", getVatOption(defaultVat), { shouldValidate: true });
        } else {
            setValue("vat", getVatOption(subscriptionItem.vat), { shouldValidate: true });
        }
    }

    const currency = watch("currency");
    if (!currency?.id && serviceOptions?.currencies) {
        register("currency");

        if (!subscriptionItem) {
            setValue("currency", getCurrencyOption(serviceOptions.currencies[0]), { shouldValidate: true });
        } else {
            setValue("currency", getCurrencyOption(subscriptionItem.currency), { shouldValidate: true });
        }
    }

    const inheritFromBooking = watch("inheritFromBooking");

    const booking = watch("booking");
    if (!booking) {
        register("booking");
    }

    register("isCreatingItem");
    setValue("isCreatingItem", !subscriptionItem ? true : false);

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {!subscriptionItem ? (
                        <FormattedMessage id="SUBSCRIPTION.ITEM.ADD.TITLE" />
                    ) : (
                        <FormattedMessage id="SUBSCRIPTION.ITEM.EDIT.TITLE" />
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...formMethods}>
                    <Form>
                        <div className={classes.formContent}>
                            {(!subscriptionItem || subscriptionItem?.bookingId) && (
                                <Form.Row>
                                    <Form.Group as={Col} xl={6}>
                                        <Form.Label>
                                            <FormattedMessage id="SUBSCRIPTION.ITEM.DETAILS.INHERIT_FROM_BOOKING" />
                                        </Form.Label>
                                        <Controller
                                            name="inheritFromBooking"
                                            control={control}
                                            defaultValue={
                                                subscriptionItem ? subscriptionItem.inheritFromBooking : false
                                            }
                                            render={({ value, onChange }) => {
                                                return (
                                                    <Switch
                                                        checked={value}
                                                        onChange={(event, value) => onChange(value)}
                                                    />
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            )}

                            {!subscriptionItem && inheritFromBooking && (
                                <Form.Row>
                                    <Form.Group as={Col} xl={8}>
                                        <Form.Label>
                                            <FormattedMessage id="SUBSCRIPTION.ITEM.DETAILS.BOOKING" />
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="booking"
                                            defaultValue={null}
                                            render={() => (
                                                <DefaultAsyncTypeahead
                                                    id="typeahead-bookings"
                                                    onChange={(bookings) => {
                                                        setValue("booking", bookings[0], {
                                                            shouldValidate: true,
                                                        });
                                                    }}
                                                    onSearch={(search) =>
                                                        getCustomerSubscriptionAvailableBookings(
                                                            customerId,
                                                            1,
                                                            20,
                                                            search
                                                        )
                                                    }
                                                    options={getBookingOptions(
                                                        customerSubscriptionAvailableBookingsPagination
                                                    )}
                                                    selected={booking}
                                                    filterBy={() => true}
                                                    clearButton={false}
                                                    placeholder={intl.formatMessage({
                                                        id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                                    })}
                                                    emptyLabel={intl.formatMessage({
                                                        id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                                    })}
                                                    isInvalid={errors.booking?.message ? true : false}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            )}

                            {(subscriptionItem || (!subscriptionItem && !inheritFromBooking)) && (
                                <>
                                    <Form.Row>
                                        <Form.Group as={Col} xl={6}>
                                            <Form.Label>
                                                <FormattedMessage id="SUBSCRIPTION.ITEM.DETAILS.NAME" />
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control form-control-lg"
                                                name="name"
                                                ref={register}
                                                isInvalid={errors.name}
                                                defaultValue={subscriptionItem?.name}
                                                disabled={inheritFromBooking}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} xl={2}>
                                            <Form.Label>
                                                <FormattedMessage id="SUBSCRIPTION.ITEM.DETAILS.QUANTITY" />
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                className="form-control form-control-lg"
                                                name="quantity"
                                                ref={register}
                                                isInvalid={errors.quantity}
                                                defaultValue={subscriptionItem ? subscriptionItem.quantity : 1}
                                                disabled={inheritFromBooking}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.quantity?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <PriceForm
                                        priceTitle={<FormattedMessage id="SERVICE.PAYMENT.PRICE" />}
                                        price={subscriptionItem?.price}
                                        pricePerUnits={[]}
                                        vats={serviceOptions?.vats}
                                        vat={vat}
                                        currencies={serviceOptions?.currencies}
                                        currency={currency}
                                        priceDisabled={inheritFromBooking}
                                        vatDisabled={inheritFromBooking}
                                        currencyDisabled={inheritFromBooking}
                                    />
                                </>
                            )}
                        </div>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isUpdating}
                    onClick={handleSubmit(onFormSubmit)}
                    label={
                        !subscriptionItem ? <FormattedMessage id="COMMON.ADD" /> : <FormattedMessage id="COMMON.SAVE" />
                    }
                />
            </Modal.Footer>
        </Modal>
    );
}

function getVatOption(vat) {
    return { id: vat.id, name: `${vat.amount}%` };
}

function getCurrencyOption(currency) {
    return { id: currency.id, name: currency.shortName };
}

function getBookingOptions(bookingPagination) {
    let options = [];
    if (!bookingPagination?.data?.length) return options;
    bookingPagination.data.forEach((booking) => {
        options.push(getBookingOption(booking));
    });

    return options;
}

function getBookingOption(booking) {
    return {
        id: booking.id,
        name: `${booking.id} - ${booking.service.name}`,
        price: booking.price,
    };
}

export default connect(null, { ...subscriptionActions, ...serviceActions, ...customerActions })(SubscriptionItemModal);
