import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import moment from "moment";
import * as subscriptionUtils from "./utils";
import { PAYMENT_TYPE_CARD } from "../transactions/utils";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    lastAtContainer: {
        paddingLeft: theme.spacing(4),
    },
}));

const schema = yup.object().shape({
    cancellationType: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
});

function SubscriptionCancelModal({ show, onCloseClicked, cancelSubscription, onSubscriptionCancelled }) {
    const { isUpdating, subscription } = useSelector((state) => state.subscriptions);
    const [cancellationType, setCancellationType] = useState(subscriptionUtils.CANCELLATION_TYPE_NOW);
    const [isLastAtDatePickerOpen, setIsLastAtDatePickerOpen] = useState(false);
    const classes = useStyles();

    const { register, handleSubmit, control, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            cancellationType: cancellationType,
        },
    });

    const onCancellationTypeChanged = (event) => {
        setCancellationType(event.target.value);
    };

    const shouldDisableDatePickerDate = (date) => {
        if (!subscription.recurringDayOfMonth) return false;
        const dateDay = date.date();

        if (dateDay === subscription.recurringDayOfMonth) {
            // Same day as subscription payment day, allow
            return false;
        }

        if (subscription.recurringDayOfMonth >= dateDay && dateDay === moment(date, "YYYY-MM").daysInMonth()) {
            // Subscription payment date is later than the current day which is also the last day of the month.
            // This means for example recurringDayOfMonth 31 and month has less days, allow this last day
            return false;
        }

        return true;
    };

    const onFormSubmit = (values) => {
        let cancelLastAt = null;
        if (values.cancelLastAt) {
            cancelLastAt = moment(values.cancelLastAt).format();
        }
        cancelSubscription(subscription.id, values.cancellationType, cancelLastAt, () => {
            setCancellationType(subscriptionUtils.CANCELLATION_TYPE_NOW);
            onSubscriptionCancelled();
        });
    };

    if (!subscription) return <></>;

    const paymentType = subscription?.paymentMethod?.type || subscription.paymentType;
    const isCardPayment = paymentType === PAYMENT_TYPE_CARD;
    const noticePeriodDays = subscription?.noticePeriodDays;

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "1299" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id={"SUBSCRIPTION.CANCEL.MODAL.TITLE"} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id={"SUBSCRIPTION.CANCEL.MODAL.DESCRIPTION"} />
                    <div className={classes.formContent}>
                        <Controller
                            control={control}
                            name="cancellationType"
                            render={({ name, onBlur, onChange, value }) => (
                                <RadioGroup
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                        onChange(e);
                                        onCancellationTypeChanged(e);
                                    }}
                                >
                                    <FormControlLabel
                                        value={subscriptionUtils.CANCELLATION_TYPE_NOW}
                                        control={<Radio />}
                                        label={
                                            <FormattedMessage
                                                id={
                                                    isCardPayment
                                                        ? "SUBSCRIPTION.CANCEL.CARD.NOW"
                                                        : "SUBSCRIPTION.CANCEL.INVOICE.NOW"
                                                }
                                            />
                                        }
                                    />
                                    {subscription.lastAtWithNoticePeriod && (
                                        <FormControlLabel
                                            value={subscriptionUtils.CANCELLATION_TYPE_NOW_NOTICE_PERIOD}
                                            control={<Radio />}
                                            label={
                                                <FormattedMessage
                                                    id={
                                                        isCardPayment
                                                            ? "SUBSCRIPTION.CANCEL.CARD.NOW_WITH_NOTICE_PERIOD"
                                                            : "SUBSCRIPTION.CANCEL.INVOICE.NOW_WITH_NOTICE_PERIOD"
                                                    }
                                                    values={{
                                                        date: moment(subscription.lastAtWithNoticePeriod).format(
                                                            "YYYY-MM-DD"
                                                        ),
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                    {noticePeriodDays && (
                                        <FormControlLabel
                                            value={subscriptionUtils.CANCELLATION_TYPE_LAST_AT}
                                            control={<Radio />}
                                            label={<FormattedMessage id={"SUBSCRIPTION.CANCEL.WITH_LAST_AT"} />}
                                        />
                                    )}
                                </RadioGroup>
                            )}
                        />
                        {noticePeriodDays && cancellationType === subscriptionUtils.CANCELLATION_TYPE_LAST_AT && (
                            <div className={classes.lastAtContainer}>
                                <div className="form-group row">
                                    <label className="col-xl-4 col-form-label">
                                        <FormattedMessage
                                            id={
                                                isCardPayment
                                                    ? "SUBSCRIPTION.CANCEL.CARD.WITH_LAST_AT_DATE"
                                                    : "SUBSCRIPTION.CANCEL.INVOICE.WITH_LAST_AT_DATE"
                                            }
                                        />
                                    </label>
                                    <div className="col-lg-6 col-xl-6">
                                        <div>
                                            <Form.Control
                                                type="text"
                                                name="cancelLastAt"
                                                ref={register}
                                                className="form-control-lg"
                                                readOnly
                                                onClick={() => setIsLastAtDatePickerOpen(true)}
                                                defaultValue={
                                                    subscription?.nextAt
                                                        ? moment(subscription.nextAt).format("YYYY-MM-DD")
                                                        : ""
                                                }
                                            />
                                            {subscription.recurringDayOfMonth && (
                                                <Form.Text className="text-muted">
                                                    <FormattedMessage
                                                        id="SUBSCRIPTION.CANCEL.WITH_LAST_AT_DATE_DESCRIPTION"
                                                        values={{ day: subscription.recurringDayOfMonth }}
                                                    />
                                                </Form.Text>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isUpdating}
                        onClick={handleSubmit(onFormSubmit)}
                        label={<FormattedMessage id="SUBSCRIPTION.CANCEL" />}
                    />
                </Modal.Footer>
                <div style={{ display: "none" }}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                            disablePast={true}
                            showTodayButton
                            ampm={false}
                            style={{ display: "none" }}
                            open={isLastAtDatePickerOpen}
                            shouldDisableDate={shouldDisableDatePickerDate}
                            onClose={() => setIsLastAtDatePickerOpen(false)}
                            onChange={(selectedMomentDate) => {
                                setValue("cancelLastAt", selectedMomentDate.format("YYYY-MM-DD"));
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </Modal>
        </Form>
    );
}

export default connect(null, subscriptionActions)(SubscriptionCancelModal);
