import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReportFilterDropdownMultiple from "./filters/ReportFilterDropdownMultiple";
import * as subscriptionUtils from "../../pages/subscriptions/utils";

const ReportFilterFormSubscriptions = ({ report, onSaveHasBeenClicked, isDisabled }) => {
    const intl = useIntl();

    const filterValues = report?.filterValues;
    const filterValuesObj = filterValues ? JSON.parse(filterValues) : {};

    const subscriptionStatusOptions = [
        {
            id: subscriptionUtils.STATUS_ACTIVE,
            name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.ACTIVE" }),
        },
        {
            id: subscriptionUtils.STATUS_UNDER_NOTICE,
            name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.UNDER_NOTICE" }),
        },
        {
            id: subscriptionUtils.STATUS_PAST_DUE,
            name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.PAST_DUE" }),
        },
        {
            id: subscriptionUtils.STATUS_PAST_DUE_CANCELLED,
            name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.PAST_DUE_CANCELLED" }),
        },
        {
            id: subscriptionUtils.STATUS_CANCELLED,
            name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.CANCELLED" }),
        },
    ];

    let defaultSubscriptionStatusOptions = [];
    if (filterValuesObj.subscriptionStatuses) {
        if (filterValuesObj.subscriptionStatuses.includes(subscriptionUtils.STATUS_ACTIVE)) {
            defaultSubscriptionStatusOptions.push({
                id: subscriptionUtils.STATUS_ACTIVE,
                name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.ACTIVE" }),
            });
        }
        if (filterValuesObj.subscriptionStatuses.includes(subscriptionUtils.STATUS_UNDER_NOTICE)) {
            defaultSubscriptionStatusOptions.push({
                id: subscriptionUtils.STATUS_UNDER_NOTICE,
                name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.UNDER_NOTICE" }),
            });
        }
        if (filterValuesObj.subscriptionStatuses.includes(subscriptionUtils.STATUS_PAST_DUE)) {
            defaultSubscriptionStatusOptions.push({
                id: subscriptionUtils.STATUS_PAST_DUE,
                name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.PAST_DUE" }),
            });
        }
        if (filterValuesObj.subscriptionStatuses.includes(subscriptionUtils.STATUS_PAST_DUE_CANCELLED)) {
            defaultSubscriptionStatusOptions.push({
                id: subscriptionUtils.STATUS_PAST_DUE_CANCELLED,
                name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.PAST_DUE_CANCELLED" }),
            });
        }
        if (filterValuesObj.subscriptionStatuses.includes(subscriptionUtils.STATUS_CANCELLED)) {
            defaultSubscriptionStatusOptions.push({
                id: subscriptionUtils.STATUS_CANCELLED,
                name: intl.formatMessage({ id: "SUBSCRIPTION.FILTER.STATUS.CANCELLED" }),
            });
        }
    }

    return (
        <>
            <ReportFilterDropdownMultiple
                name="subscriptionStatuses"
                options={subscriptionStatusOptions}
                defaultValues={defaultSubscriptionStatusOptions}
                title={<FormattedMessage id="REPORT.SUBSCRIPTIONS.FILTER.SUBSCRIPTION_STATUSES.TITLE" />}
                isDisabled={isDisabled}
            />
        </>
    );
};

export default ReportFilterFormSubscriptions;
