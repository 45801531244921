import React from "react";
import { actions as paymentTemplateActions } from "../../../redux/paymenttemplates/paymentTemplateRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import PaymentTemplateDetailsForm from "./PaymentTemplateDetailsForm";
import {
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    PAYMENT_TYPE_MODE_ONE_TIME,
    paymentTemplateInputSchema,
} from "./utils";

function PaymentTemplatePageDetails({ updatePaymentTemplate }) {
    const { paymentTemplate, isUpdating } = useSelector((state) => state.paymentTemplates);

    const formMethods = useForm({
        resolver: yupResolver(paymentTemplateInputSchema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalTemplate = { ...paymentTemplate };
        originalTemplate.billingTemplateId = originalTemplate?.billingTemplate?.id || null;
        originalTemplate.isSwishEnabled = originalTemplate.paymentMethods.includes(PAYMENT_METHOD_SWISH);
        originalTemplate.isCardEnabled = originalTemplate.paymentMethods.includes(PAYMENT_METHOD_CARD);
        originalTemplate.isInvoiceEnabled = originalTemplate.paymentMethods.includes(PAYMENT_METHOD_INVOICE);

        let updatedTemplate = { ...paymentTemplate };
        updatedTemplate.name = values.name;
        updatedTemplate.isDefault = values.isDefault;
        updatedTemplate.paymentMode = values.paymentMode;
        updatedTemplate.billingTemplateId = values?.billingTemplate?.id;

        if (values.paymentMethods) {
            updatedTemplate.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
            updatedTemplate.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
            updatedTemplate.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
        }

        if (values.paymentTypeMode) {
            if (values.paymentTypeMode === PAYMENT_TYPE_MODE_ONE_TIME) {
                updatedTemplate.isSubscription = false;
            } else {
                updatedTemplate.isSubscription = true;
                updatedTemplate.subscriptionUnit = values.subscriptionUnit?.id;
                updatedTemplate.subscriptionDayMode = values.subscriptionDayMode?.id;
                updatedTemplate.subscriptionDayOfMonth = values.subscriptionDayOfMonth;
                updatedTemplate.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;
                updatedTemplate.subscriptionNoticePeriodTransactions = values.subscriptionNoticePeriodTransactions;
                updatedTemplate.isSubscriptionExtendOnFailure = values.isSubscriptionExtendOnFailure;
            }
        }

        updatePaymentTemplate(paymentTemplate.id, originalTemplate, updatedTemplate);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {paymentTemplate && !paymentTemplate?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <PaymentTemplateDetailsForm paymentTemplate={paymentTemplate} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, paymentTemplateActions)(PaymentTemplatePageDetails);
