import React, { useEffect, useState } from "react";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import * as subscriptionUtils from "./utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import DefaultAsyncTypeahead from "../../components/DefaultAsyncTypeahead";
import { TYPE_INVOICE } from "../paymentmethods/utils";
import PaymentMethodAddModal from "../paymentmethods/PaymentMethodAddModal";

const schema = yup.object().shape({
    amount: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />),
});

function SubscriptionPageDetails({ updateSubscription, getCustomerPaymentMethods }) {
    const { subscription, isUpdating } = useSelector((state) => state.subscriptions);
    const { customerPaymentMethodsPagination } = useSelector((state) => state.customers);
    const intl = useIntl();

    const { control, setValue, watch, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);

    useEffect(() => {
        if (subscription?.id) {
            getCustomerPaymentMethods(subscription.customer.id, 1, 20, null, TYPE_INVOICE);

            if (subscription.paymentMethod) {
                setValue("paymentMethod", getPaymentMethodOption(subscription.paymentMethod));
            }
        }
    }, [
        subscription?.id,
        subscription?.customer?.id,
        subscription?.paymentMethod,
        getCustomerPaymentMethods,
        setValue,
    ]);

    const onFormSubmit = (values) => {
        const updatedSubscription = {
            ...subscription,
        };

        if (values.paymentMethod) {
            updatedSubscription.paymentMethodId = values.paymentMethod.id;
        }

        updateSubscription(subscription.id, subscription, updatedSubscription);
    };

    const paymentMethod = watch("paymentMethod");

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SUBSCRIPTION.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SUBSCRIPTION.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {subscription && !subscription.deletedAt && !subscription.externalRef && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.PAYMENT_METHOD" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="paymentMethod"
                                    defaultValue={null}
                                    render={() => (
                                        <DefaultAsyncTypeahead
                                            id="typeahead-payment-methods"
                                            onChange={(paymentMethods) => {
                                                setValue("paymentMethod", paymentMethods[0], {
                                                    shouldValidate: true,
                                                });
                                            }}
                                            onSearch={(search) =>
                                                getCustomerPaymentMethods(
                                                    subscription.customer.id,
                                                    1,
                                                    20,
                                                    search,
                                                    TYPE_INVOICE
                                                )
                                            }
                                            isLoading={false}
                                            options={getPaymentMethodOptions(customerPaymentMethodsPagination)}
                                            selected={paymentMethod}
                                            filterBy={() => true}
                                            clearButton={false}
                                            placeholder={intl.formatMessage({
                                                id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                            })}
                                            emptyLabel={intl.formatMessage({
                                                id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                            })}
                                            onAddButtonClicked={() => setShowAddPaymentMethodModal(true)}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xl={3}>
                                <Form.Label>
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.AMOUNT" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg"
                                    readOnly
                                    disabled
                                    defaultValue={subscription?.amount}
                                />
                                <Form.Control.Feedback type="invalid">{errors.amount?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xl={2}>
                                <Form.Label>
                                    <FormattedMessage id="COMMON.CURRENCY" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg"
                                    readOnly
                                    disabled
                                    value={subscription ? subscription.currency : ""}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.RECURRING_UNIT" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg"
                                    readOnly
                                    disabled
                                    value={subscriptionUtils.getRecurringUnitText(subscription?.recurringUnit, intl)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.RECCURRING_DAY_OF_MONTH" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="form-control form-control-lg"
                                    readOnly
                                    disabled
                                    value={subscription?.recurringDayOfMonth ? subscription.recurringDayOfMonth : ""}
                                />
                                <Form.Text className="text-muted">
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.RECCURRING_DAY_OF_MONTH_DESCRIPTION" />
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        {subscription && subscription.status !== subscriptionUtils.STATUS_CANCELLED && (
                            <>
                                <Form.Group>
                                    <Form.Label>
                                        {subscription.paymentMethod?.type === TYPE_INVOICE ? (
                                            <FormattedMessage id="SUBSCRIPTION.DETAILS.NEXT_AT.INVOICE" />
                                        ) : (
                                            <FormattedMessage id="SUBSCRIPTION.DETAILS.NEXT_AT" />
                                        )}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control form-control-lg col-xl-3"
                                        readOnly
                                        disabled
                                        value={
                                            subscription?.nextAt ? moment(subscription.nextAt).format("YYYY-MM-DD") : ""
                                        }
                                    />
                                </Form.Group>
                                {subscription?.lastAt && (
                                    <Form.Group>
                                        <Form.Label>
                                            {subscription.paymentMethod?.type === TYPE_INVOICE ? (
                                                <FormattedMessage id="SUBSCRIPTION.DETAILS.LAST_AT.INVOICE" />
                                            ) : (
                                                <FormattedMessage id="SUBSCRIPTION.DETAILS.LAST_AT" />
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control form-control-lg col-xl-3"
                                            readOnly
                                            disabled
                                            value={moment(subscription.lastAt).format("YYYY-MM-DD")}
                                        />
                                        <Form.Text className="text-muted">
                                            {subscription.paymentMethod?.type === TYPE_INVOICE ? (
                                                <FormattedMessage id="SUBSCRIPTION.DETAILS.LAST_AT_DESCRIPTION.INVOICE" />
                                            ) : (
                                                <FormattedMessage id="SUBSCRIPTION.DETAILS.LAST_AT_DESCRIPTION" />
                                            )}
                                        </Form.Text>
                                    </Form.Group>
                                )}
                            </>
                        )}
                        {subscription?.noticePeriodDays && (
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.NOTICE_PERIOD.DAYS" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="form-control form-control-lg col-xl-2"
                                    readOnly
                                    disabled
                                    value={subscription?.noticePeriodDays ? subscription.noticePeriodDays : ""}
                                />
                            </Form.Group>
                        )}
                        {subscription?.noticePeriodTransactions && (
                            <Form.Row>
                                <Form.Group as={Col} xl={3}>
                                    <Form.Label>
                                        <FormattedMessage id="SUBSCRIPTION.DETAILS.NOTICE_PERIOD.TRANSACTIONS" />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="form-control form-control-lg"
                                        readOnly
                                        disabled
                                        value={subscription.noticePeriodTransactions}
                                    />
                                </Form.Group>
                                {subscription?.remainingTransactions >= 0 && (
                                    <Form.Group as={Col} xl={3}>
                                        <Form.Label>
                                            <FormattedMessage id="SUBSCRIPTION.DETAILS.REMAINING_TRANSACTIONS" />
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            className="form-control form-control-lg"
                                            readOnly
                                            disabled
                                            value={subscription.remainingTransactions}
                                        />
                                    </Form.Group>
                                )}
                            </Form.Row>
                        )}
                    </Form>
                </Card.Body>
            </div>
            {showAddPaymentMethodModal && (
                <PaymentMethodAddModal
                    show={showAddPaymentMethodModal ? true : false}
                    customerId={subscription.customer.id}
                    onCloseClicked={() => setShowAddPaymentMethodModal(false)}
                    onPaymentMethodAdded={(paymentMethod) => {
                        setShowAddPaymentMethodModal(false);
                        setValue("paymentMethod", getPaymentMethodOption(paymentMethod), {
                            shouldValidate: true,
                        });
                    }}
                />
            )}
        </Card>
    );
}

export default connect(null, { ...subscriptionActions, ...customerActions })(SubscriptionPageDetails);

function getPaymentMethodOptions(paymentMethodPagination) {
    let options = [];
    if (!paymentMethodPagination?.data?.length) return options;
    paymentMethodPagination.data.forEach((paymentMethod) => {
        options.push(getPaymentMethodOption(paymentMethod));
    });

    return options;
}

function getPaymentMethodOption(paymentMethod) {
    return {
        id: paymentMethod.id,
        name: paymentMethod.name,
    };
}
