import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFormContext, Controller } from "react-hook-form";
import { PRICE_PER_UNIT_MINUTE, PRICE_PER_UNIT_HOUR, PRICE_PER_UNIT_DAY } from "./utils";

export const PriceForm = ({
    priceTitle,
    priceDescription,
    price,
    pricePerUnits,
    pricePerUnit,
    vats,
    vat,
    currencies,
    currency,
    priceDisabled = false,
    vatDisabled = false,
    currencyDisabled = false,
}) => {
    const intl = useIntl();

    const { register, control, setValue, errors } = useFormContext();

    return (
        <Form.Row>
            <Form.Group as={Col} xl={3}>
                <Form.Label>{priceTitle}</Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg"
                    name="price"
                    ref={register}
                    isInvalid={errors.price}
                    defaultValue={price}
                    disabled={priceDisabled}
                />
                <Form.Control.Feedback type="invalid">{errors.price?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">{priceDescription}</Form.Text>
            </Form.Group>
            {pricePerUnits.length > 0 && (
                <Form.Group as={Col} xl={2}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.PRICE_PER_UNIT" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="pricePerUnit"
                        defaultValue={null}
                        render={() => (
                            <div>
                                <Typeahead
                                    id="typeahead-payment-pricePerUnit"
                                    labelKey={"name"}
                                    onChange={(pricePerUnits) =>
                                        setValue("pricePerUnit", pricePerUnits[0], { shouldValidate: true })
                                    }
                                    options={pricePerUnits.map((pricePerUnit) =>
                                        getPricePerUnitOption(pricePerUnit, intl)
                                    )}
                                    placeholder={intl.formatMessage({
                                        id: "SERVICE.PAYMENT.PRICE_PER_UNIT.PLACEHOLDER",
                                    })}
                                    selected={pricePerUnit ? [pricePerUnit] : []}
                                    filterBy={() => true}
                                    inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                />
                            </div>
                        )}
                    />
                </Form.Group>
            )}
            <Form.Group as={Col} xl={2}>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PAYMENT.VAT" />
                </Form.Label>
                <Controller
                    control={control}
                    name="vat"
                    defaultValue={null}
                    render={() => (
                        <div>
                            <Typeahead
                                id="typeahead-payment-vat"
                                labelKey={"name"}
                                onChange={(vats) => setValue("vat", vats[0], { shouldValidate: true })}
                                options={!vats ? [] : vats.map((vat) => getVatOption(vat))}
                                placeholder={intl.formatMessage({
                                    id: "SERVICE.PAYMENT.VAT.PLACEHOLDER",
                                })}
                                selected={vat ? [vat] : []}
                                filterBy={() => true}
                                inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                disabled={vatDisabled}
                            />
                        </div>
                    )}
                />
            </Form.Group>
            <Form.Group as={Col} xl={2}>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PAYMENT.CURRENCY" />
                </Form.Label>
                <Controller
                    control={control}
                    name="currency"
                    defaultValue={null}
                    render={() => (
                        <div>
                            <Typeahead
                                id="typeahead-payment-currency"
                                labelKey={"name"}
                                onChange={(currencies) => setValue("currency", currencies[0], { shouldValidate: true })}
                                options={!currencies ? [] : currencies.map((currency) => getCurrencyOption(currency))}
                                placeholder={intl.formatMessage({
                                    id: "SERVICE.PAYMENT.CURRENCY.PLACEHOLDER",
                                })}
                                selected={currency ? [currency] : []}
                                filterBy={() => true}
                                inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                disabled={currencyDisabled}
                            />
                        </div>
                    )}
                />
            </Form.Group>
        </Form.Row>
    );
};

function getPricePerUnitOption(id, intl) {
    if (id === PRICE_PER_UNIT_MINUTE) {
        return {
            id: PRICE_PER_UNIT_MINUTE,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICE_PER_UNIT.MINUTE",
            }),
        };
    } else if (id === PRICE_PER_UNIT_HOUR) {
        return {
            id: PRICE_PER_UNIT_HOUR,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICE_PER_UNIT.HOUR",
            }),
        };
    } else if (id === PRICE_PER_UNIT_DAY) {
        return {
            id: PRICE_PER_UNIT_DAY,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICE_PER_UNIT.DAY",
            }),
        };
    }
}

function getVatOption(vat) {
    return { id: vat.id, name: `${vat.amount}%` };
}

function getCurrencyOption(currency) {
    return { id: currency.id, name: currency.shortName };
}
