import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { Card, Dropdown } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import * as transactionUtils from "./utils";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "325px",
        maxWidth: "325px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(2),
        alignItems: "center",
    },
    errorCodeMessageContainer: {
        marginTop: "2.25rem",
    },
}));

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
        <span
            ref={ref}
            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            onClick={(e) => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </span>
    );
});

function TransactionPageNavigator({ transactionId, deleteTransaction }) {
    const { transaction } = useSelector((state) => state.transactions);
    const [isMenuDropdownOpen, setMenuDropdownOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const classes = useStyles();
    const intl = useIntl();

    const onDeleteTransactionClicked = (e) => {
        e.preventDefault();
        setMenuDropdownOpen(false);
        setShowDeleteConfirmation(true);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            deleteTransaction(transactionId);
        }
        setShowDeleteConfirmation(false);
    };

    const isTransactionRefundSupported = () => {
        return (
            transaction?.paymentType === transactionUtils.PAYMENT_TYPE_CARD ||
            transaction?.paymentType === transactionUtils.PAYMENT_TYPE_SWISH
        );
    };

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                        <Dropdown
                            className="dropdown dropdown-inline"
                            alignRight
                            show={isMenuDropdownOpen}
                            onToggle={() => setMenuDropdownOpen(!isMenuDropdownOpen)}
                        >
                            <Dropdown.Toggle
                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                variant="transparent"
                                id="dropdown-toggle-top-user-profile"
                                as={DropdownCustomToggler}
                            >
                                <i className="ki ki-bold-more-hor"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <ul className="navi navi-hover py-5">
                                    {!transaction?.deletedAt && (
                                        <li className="navi-item" onClick={onDeleteTransactionClicked}>
                                            <a href="/" className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="flaticon2-trash"></i>
                                                </span>
                                                <span className="navi-text">
                                                    <FormattedMessage id="PAYMENT.DELETE" />
                                                </span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        {transaction && (
                            <>
                                <span className="font-weight-bolder font-size-h6 text-dark-75 flex-grow-1">
                                    <FormattedMessage
                                        id="PAYMENT.DETAILS.TITLE_NAVIGATOR"
                                        values={{ id: transactionId }}
                                    />
                                </span>
                                {transactionUtils.getTransactionStatusCell(
                                    transaction.paymentStatus,
                                    transaction.status
                                )}
                            </>
                        )}
                    </div>

                    {transaction?.status === transactionUtils.STATUS_FAILED && transaction?.errorCode && (
                        <Alert icon={false} severity="error" className={classes.errorCodeMessageContainer}>
                            {transactionUtils.getTransactionErrorCodeMessage(transaction.errorCode, intl)}
                        </Alert>
                    )}

                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="PAYMENT.DETAILS.CREATED_AT" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {moment(transaction?.createdAt).format("YYYY-MM-DD HH:mm")}
                            </span>
                        </div>
                        {transaction?.customer && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT.DETAILS.CUSTOMER" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/customers/${transaction.customer.id}`}>
                                        {`${transaction.customer.firstName} ${transaction.customer.surname}`}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {transaction?.amount && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT.DETAILS.AMOUNT" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${transaction.amount} ${transaction.currency}`}
                                </span>
                            </div>
                        )}
                        {transaction?.subscription && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT.DETAILS.SUBSCRIPTION" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/subscriptions/${transaction.subscription.id}`}>
                                        {transaction.subscription.id}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {transaction?.refundedAmount > 0 && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT.DETAILS.AMOUNT_REFUNDED" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${transaction.refundedAmount} ${transaction.currency}`}
                                </span>
                            </div>
                        )}
                        {transaction?.promotionCode && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT.DETAILS.PROMO_CODE" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/promotion-codes/${transaction.promotionCode.id}`}>
                                        {`${transaction.promotionCode.code}`}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {transaction?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT.DELETED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(transaction.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {transaction?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT.DELETED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${transaction.deletedBy.firstName} ${transaction.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/transactions/${transactionId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PAYMENT.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/transactions/${transactionId}/bookings`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PAYMENT.BOOKINGS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {isTransactionRefundSupported() && (
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink
                                    to={`/transactions/${transactionId}/refunds`}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}></SVG>{" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        <FormattedMessage id="PAYMENT.REFUNDS.TITLE" />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    )}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/transactions/${transactionId}/document-versions`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Folder-cloud.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <YesNoDialog
                title={<FormattedMessage id="PAYMENT.DELETE.TEXT" />}
                open={showDeleteConfirmation}
                onOpenChanged={() => setShowDeleteConfirmation(false)}
                onActionClicked={onDeleteDialogActionClicked}
            />
        </div>
    );
}

export default connect(null, transactionActions)(TransactionPageNavigator);
