import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import CustomerPageNavigator from "./CustomerPageNavigator";
import CustomerPageDetails from "./CustomerPageDetails";
import CustomerPageBookings from "./CustomerPageBookings";
import CustomerPageBookingResources from "./CustomerPageBookingResources";
import CustomerPageTransactions from "./CustomerPageTransactions";
import CustomerPageSubscriptions from "./CustomerPageSubscriptions";
import CustomerPageVouchers from "./CustomerPageVouchers";
import CustomerPageMemberships from "./CustomerPageMemberships";
import CustomerPageUserProxies from "./CustomerPageUserProxies";
import CustomerPageExternalAccounts from "./CustomerPageExternalAccounts";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function CustomerPage({ match, getCustomer, clearCustomerState, clearDisplayError, clearErrorState }) {
    const customerId = parseInt(match.params.id, 10);
    const { customer, isLoading, isUpdating } = useSelector((state) => state.customers);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!customer || customer.id !== customerId)) {
            getCustomer(customerId);
        }
    }, [getCustomer, customer, customerId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearCustomerState();
            clearErrorState();
        };
    }, [clearCustomerState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <CustomerPageNavigator customerId={customerId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/customers/${customerId}`}
                            exact={true}
                            to={`/customers/${customerId}/details`}
                        />
                        <Route path={`/customers/${customerId}/details`} component={CustomerPageDetails} />
                        <Route path={`/customers/${customerId}/bookings`} component={CustomerPageBookings} />
                        <Route
                            path={`/customers/${customerId}/booking-resources`}
                            component={CustomerPageBookingResources}
                        />
                        <Route path={`/customers/${customerId}/transactions`} component={CustomerPageTransactions} />
                        <Route path={`/customers/${customerId}/subscriptions`} component={CustomerPageSubscriptions} />
                        <Route path={`/customers/${customerId}/vouchers`} component={CustomerPageVouchers} />
                        <Route path={`/customers/${customerId}/memberships`} component={CustomerPageMemberships} />
                        <Route path={`/customers/${customerId}/relatives`} component={CustomerPageUserProxies} />
                        <Route
                            path={`/customers/${customerId}/external-accounts`}
                            component={CustomerPageExternalAccounts}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...customerActions, ...errorActions })(CustomerPage);
