import React, { useMemo, useCallback, useState } from "react";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TextCell, LinkCell } from "../../components/tables/MaterialTableCells";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SubscriptionItemModal from "./SubscriptionItemModal";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import { STATUS_ACTIVE, STATUS_UNDER_NOTICE, STATUS_PAST_DUE } from "./utils";

function SubscriptionPageItems({ getSubscriptionItems, deleteSubscriptionItem }) {
    const { subscription, subscriptionItemsPagination, isLoading } = useSelector((state) => state.subscriptions);
    const { displayError } = useSelector((state) => state.errors);
    const subscriptionId = subscription?.id;
    const intl = useIntl();

    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (subscriptionId) {
                getSubscriptionItems(subscriptionId, pageIndex + 1, pageSize);
            }
        },
        [subscriptionId, getSubscriptionItems]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SUBSCRIPTION.ITEMS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SUBSCRIPTION.ITEMS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        {(subscription?.status === STATUS_ACTIVE ||
                            subscription?.status === STATUS_UNDER_NOTICE ||
                            subscription?.status === STATUS_PAST_DUE) && (
                            <BootstrapSaveButton
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowAddItemModal(true)}
                            />
                        )}
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={subscriptionId}
                        data={useMemo(() => subscriptionItemsPagination.data, [subscriptionItemsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={subscriptionItemsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.ITEMS.LIST.NAME" />,
                                    accessor: "name",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.ITEMS.LIST.QUANTITY" />,
                                    accessor: "quantity",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.ITEMS.LIST.VAT" />,
                                    accessor: "vat",
                                    Cell: (props) => {
                                        return TextCell(`${props.value.amount}%`);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.ITEMS.LIST.PRICE" />,
                                    accessor: "price",
                                    Cell: ({ row }) => {
                                        const price = row.original.price;
                                        const currencyName = row.original.currency.shortName;
                                        return TextCell(`${price} ${currencyName}`);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.ITEMS.LIST.BOOKING" />,
                                    accessor: "bookingId",
                                    Cell: ({ row }) => {
                                        const bookingId = row.original.bookingId;

                                        if (bookingId) {
                                            if (row.original.inheritFromBooking) {
                                                return LinkCell(
                                                    "/bookings/" + bookingId,
                                                    `${bookingId} ${intl.formatMessage({
                                                        id: "SUBSCRIPTION.ITEMS.LIST.BOOKING.INHERIT",
                                                    })}`
                                                );
                                            }

                                            return LinkCell(
                                                "/bookings/" + bookingId,
                                                `${bookingId} ${intl.formatMessage({
                                                    id: "SUBSCRIPTION.ITEMS.LIST.BOOKING.NO_INHERIT",
                                                })}`
                                            );
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "actions",
                                    textAlign: "center",
                                    Cell: ({ row }) => {
                                        return (
                                            <ListActionsButton
                                                menuItems={[
                                                    {
                                                        icon: <LinkIcon fontSize="small" />,
                                                        label: (
                                                            <FormattedMessage id="SUBSCRIPTION.ITEMS.LIST.ACTION.ADJUST" />
                                                        ),
                                                        onClick: () => {
                                                            setShowAddItemModal(true);
                                                            setItemToEdit(row.original);
                                                        },
                                                    },
                                                    {
                                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.DELETE" />,
                                                        onClick: () => setItemToDelete(row.original),
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ],
                            [intl]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {showAddItemModal && (
                <SubscriptionItemModal
                    show={showAddItemModal ? true : false}
                    subscriptionId={subscriptionId}
                    customerId={subscription.customer.id}
                    subscriptionItem={itemToEdit}
                    onCloseClicked={() => {
                        setShowAddItemModal(false);
                        setItemToEdit(null);
                    }}
                    onItemAdded={(item) => {
                        setShowAddItemModal(false);
                        setItemToEdit(null);
                        fetchData({ pageIndex: 0, pageSize: 10 });
                    }}
                    onItemUpdated={(item) => {
                        setShowAddItemModal(false);
                        setItemToEdit(null);
                        fetchData({ pageIndex: 0, pageSize: 10 });
                    }}
                />
            )}
            <YesNoDialog
                title={<FormattedMessage id="SUBSCRIPTION.ITEM.DELETE.TEXT" />}
                open={itemToDelete ? true : false}
                onOpenChanged={() => setItemToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteSubscriptionItem(itemToDelete.id, () => {
                            fetchData({ pageIndex: 0, pageSize: 10 });
                        });
                    }
                    setItemToDelete(null);
                }}
            />
        </Card>
    );
}

export default connect(null, {
    ...subscriptionActions,
    ...errorActions,
})(SubscriptionPageItems);
