import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card, Col } from "react-bootstrap";
import { makeStyles, Avatar, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapAddButtons from "../../components/buttons/BootstrapAddButtons";
import SnackbarError from "../../components/SnackbarError";
import { useIntl } from "react-intl";
import DefaultAsyncTypeahead from "../../components/DefaultAsyncTypeahead";
import { TYPE_INVOICE } from "../paymentmethods/utils";
import PaymentMethodAddModal from "../paymentmethods/PaymentMethodAddModal";

const useStyles = makeStyles((theme) => ({
    listItemAvatarContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemLabelContainer: {
        display: "flex",
        flexDirection: "column",
    },
    listItemAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
    formRowButton: {
        alignItems: "center",
    },
}));

const schema = yup.object().shape({
    customer: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    paymentMethod: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    booking: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    amount: yup
        .number()
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
        .min(5, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 5 }} />),
});

function SubscriptionPageAdd({
    addSubscription,
    clearSubscriptionState,
    getCustomers,
    getCustomerPaymentMethods,
    getCustomerSubscriptionAvailableBookings,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { subscription, isUpdating } = useSelector((state) => state.subscriptions);
    const {
        isLoading: isCustomersLoading,
        listPagination: customersListPagination,
        customerPaymentMethodsPagination,
        customerSubscriptionAvailableBookingsPagination,
    } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);

    const classes = useStyles();
    const intl = useIntl();

    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const { register, control, setValue, watch, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const customer = watch("customer");
    if (!customer) {
        register("customer");
    }

    const paymentMethod = watch("paymentMethod");
    if (!paymentMethod) {
        register("paymentMethod");
    }

    const booking = watch("booking");
    if (!booking) {
        register("booking");
    }

    useEffect(() => {
        getCustomers(profile.id, 1, 20);
    }, [profile.id, getCustomers]);

    useEffect(() => {
        if (customer?.id) {
            getCustomerPaymentMethods(customer.id, 1, 20, null, TYPE_INVOICE);
            getCustomerSubscriptionAvailableBookings(customer.id, 1, 20);
        }
    }, [customer?.id, getCustomerPaymentMethods, getCustomerSubscriptionAvailableBookings]);

    useEffect(() => {
        return () => {
            clearSubscriptionState();
            clearErrorState();
        };
    }, [clearSubscriptionState, clearErrorState]);

    const onFormSubmitRedirect = (values) => {
        addFromForm(values, true);
    };

    const onFormSubmitAddAnother = (values) => {
        addFromForm(values, false);
    };

    const addFromForm = (formData, redirect) => {
        let requestData = {
            ...formData,
        };

        requestData.customerId = requestData.customer.id;
        requestData.paymentMethodId = requestData.paymentMethod.id;
        requestData.bookingId = requestData.booking.id;

        delete requestData.customer;
        delete requestData.paymentMethod;
        delete requestData.booking;

        addSubscription(profile.id, requestData, () => {
            if (redirect) {
                setRedirect(true);
            } else {
                reset();
            }
        });
    };

    if (redirect && subscription) {
        return <Redirect to={`/subscriptions/${subscription.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>

            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">
                        <FormattedMessage id="SUBSCRIPTION.CREATE.TITLE" />
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="SUBSCRIPTION.CREATE.CUSTOMER" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="customer"
                                    defaultValue={null}
                                    render={() => (
                                        <DefaultAsyncTypeahead
                                            id="typeahead-customers"
                                            onChange={(customers) => {
                                                setValue("customer", customers[0], {
                                                    shouldValidate: true,
                                                });
                                            }}
                                            onSearch={(search) => getCustomers(profile.id, 1, 20, search)}
                                            isLoading={isCustomersLoading}
                                            options={getCustomerOptions(customersListPagination)}
                                            selected={customer}
                                            filterBy={() => true}
                                            clearButton={true}
                                            renderMenuItemChildren={(option, props) => (
                                                <div className={classes.listItemAvatarContainer}>
                                                    <Avatar
                                                        alt={option.name}
                                                        src={option.avatarUrl}
                                                        className={classes.listItemAvatar}
                                                    />
                                                    <div className={classes.listItemLabelContainer}>
                                                        <Typography variant="body1">{option.name}</Typography>
                                                        <Typography variant="body2">{option.email}</Typography>
                                                    </div>
                                                </div>
                                            )}
                                            placeholder={intl.formatMessage({
                                                id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                            })}
                                            emptyLabel={intl.formatMessage({
                                                id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                            })}
                                            isInvalid={errors.customer?.message ? true : false}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Form.Row>
                        {customer && (
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="SUBSCRIPTION.CREATE.PAYMENT_METHOD" />
                                </Form.Label>
                                <Form.Row className={classes.formRowButton}>
                                    <Col xl={4} sm={8}>
                                        <Controller
                                            control={control}
                                            name="paymentMethod"
                                            defaultValue={null}
                                            render={() => (
                                                <DefaultAsyncTypeahead
                                                    id="typeahead-payment-methods"
                                                    onChange={(paymentMethods) => {
                                                        setValue("paymentMethod", paymentMethods[0], {
                                                            shouldValidate: true,
                                                        });
                                                    }}
                                                    onSearch={(search) =>
                                                        getCustomerPaymentMethods(
                                                            customer.id,
                                                            1,
                                                            20,
                                                            search,
                                                            TYPE_INVOICE
                                                        )
                                                    }
                                                    isLoading={isCustomersLoading}
                                                    options={getPaymentMethodOptions(customerPaymentMethodsPagination)}
                                                    selected={paymentMethod}
                                                    filterBy={() => true}
                                                    clearButton={false}
                                                    placeholder={intl.formatMessage({
                                                        id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                                    })}
                                                    emptyLabel={intl.formatMessage({
                                                        id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                                    })}
                                                    isInvalid={errors.paymentMethod?.message ? true : false}
                                                    onAddButtonClicked={() => setShowAddPaymentMethodModal(true)}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        )}
                        {paymentMethod && (
                            <Form.Row>
                                <Form.Group as={Col} xl={4}>
                                    <Form.Label>
                                        <FormattedMessage id="SUBSCRIPTION.CREATE.BOOKING" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="booking"
                                        defaultValue={null}
                                        render={() => (
                                            <DefaultAsyncTypeahead
                                                id="typeahead-bookings"
                                                onChange={(bookings) => {
                                                    setValue("booking", bookings[0], {
                                                        shouldValidate: true,
                                                    });
                                                }}
                                                onSearch={(search) =>
                                                    getCustomerSubscriptionAvailableBookings(customer.id, 1, 20, search)
                                                }
                                                isLoading={isCustomersLoading}
                                                options={getBookingOptions(
                                                    customerSubscriptionAvailableBookingsPagination
                                                )}
                                                selected={booking}
                                                filterBy={() => true}
                                                clearButton={false}
                                                placeholder={intl.formatMessage({
                                                    id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                                })}
                                                emptyLabel={intl.formatMessage({
                                                    id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                                })}
                                                isInvalid={errors.booking?.message ? true : false}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Form.Row>
                        )}
                        {booking && (
                            <Form.Row>
                                <Form.Group as={Col} xl={2}>
                                    <Form.Label>
                                        <FormattedMessage id="SUBSCRIPTION.CREATE.AMOUNT" />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="form-control form-control-lg"
                                        name="amount"
                                        ref={register}
                                        isInvalid={errors.amount}
                                        defaultValue={booking.price}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.amount?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        )}
                        {customer && paymentMethod && booking && (
                            <BootstrapAddButtons
                                addButtonIsLoading={isUpdating}
                                addAnotherButtonIsLoading={isUpdating}
                                addButtonOnClick={handleSubmit(onFormSubmitRedirect)}
                                addAnotherButtonOnClick={handleSubmit(onFormSubmitAddAnother)}
                            />
                        )}
                    </Form>
                </Card.Body>
            </Card>
            {showAddPaymentMethodModal && (
                <PaymentMethodAddModal
                    show={showAddPaymentMethodModal ? true : false}
                    customerId={customer.id}
                    onCloseClicked={() => setShowAddPaymentMethodModal(false)}
                    onPaymentMethodAdded={(paymentMethod) => {
                        setShowAddPaymentMethodModal(false);
                        setValue("paymentMethod", getPaymentMethodOption(paymentMethod), {
                            shouldValidate: true,
                        });
                    }}
                />
            )}
        </div>
    );
}

export default connect(null, { ...subscriptionActions, ...customerActions, ...errorActions })(SubscriptionPageAdd);

function getCustomerOptions(customerPagination) {
    let options = [];
    if (!customerPagination?.data?.length) return options;
    customerPagination.data.forEach((customer) => {
        options.push(getCustomerOption(customer));
    });

    return options;
}

function getCustomerOption(customer) {
    return {
        id: customer.id,
        name: `${customer.firstName} ${customer.surname}`,
        avatarUrl: customer.avatarUrl,
        email: customer.email,
    };
}

function getPaymentMethodOptions(paymentMethodPagination) {
    let options = [];
    if (!paymentMethodPagination?.data?.length) return options;
    paymentMethodPagination.data.forEach((paymentMethod) => {
        options.push(getPaymentMethodOption(paymentMethod));
    });

    return options;
}

function getPaymentMethodOption(paymentMethod) {
    return {
        id: paymentMethod.id,
        name: paymentMethod.name,
    };
}

function getBookingOptions(bookingPagination) {
    let options = [];
    if (!bookingPagination?.data?.length) return options;
    bookingPagination.data.forEach((booking) => {
        options.push(getBookingOption(booking));
    });

    return options;
}

function getBookingOption(booking) {
    return {
        id: booking.id,
        name: `${booking.id} - ${booking.service.name}`,
        price: booking.price,
    };
}
