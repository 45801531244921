import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import PaymentTemplateDetailsForm from "./PaymentTemplateDetailsForm";
import { actions as paymentTemplateActions } from "../../../redux/paymenttemplates/paymentTemplateRedux";
import {
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    paymentTemplateInputSchema,
} from "./utils";

const useStyles = makeStyles((theme) => ({
    formContent: {
        padding: theme.spacing(2),
    },
}));

function PaymentTemplateAddModal({ show, addProfilePaymentTemplate, onPaymentTemplateAdded, onCloseClicked }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading } = useSelector((state) => state.paymentTemplates);
    const classes = useStyles();
    const profileId = profile.id;

    const formMethods = useForm({
        resolver: yupResolver(paymentTemplateInputSchema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        var templateData = {
            name: values.name,
            isDefault: values.isDefault,
            paymentMode: values.paymentMode,
            billingTemplateId: values?.billingTemplate?.id,
        };

        if (values.paymentMethods) {
            templateData.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
            templateData.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
            templateData.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
        }

        if (values.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION) {
            templateData.isSubscription = true;
            templateData.subscriptionUnit = values.subscriptionUnit.id;
            templateData.subscriptionDayMode = values.subscriptionDayMode.id;
            templateData.subscriptionDayOfMonth = values.subscriptionDayOfMonth;
            templateData.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;
            templateData.subscriptionNoticePeriodTransactions = values.subscriptionNoticePeriodTransactions;
            templateData.isSubscriptionExtendOnFailure = values.isSubscriptionExtendOnFailure;
        }

        addProfilePaymentTemplate(profileId, templateData, (addedTemplate) => {
            onPaymentTemplateAdded(addedTemplate);
        });
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="PAYMENT_TEMPLATE.CREATE.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...formMethods}>
                    <Form>
                        <div className={classes.formContent}>
                            <PaymentTemplateDetailsForm />
                        </div>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id="COMMON.ADD" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default connect(null, { ...paymentTemplateActions })(PaymentTemplateAddModal);
