import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as billingTemplateActions } from "../../../redux/billingtemplates/billingTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../components/SnackbarError";
import BillingTemplateDetailsForm from "./BillingTemplateDetailsForm";
import moment from "moment";

const schema = yup.object().shape(
    {
        name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
        organisationName: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
        organisationNumber: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
        vatNumber: yup.string(),
        invoiceExpirationDays: yup.number().when("invoiceExpirationDate", (invoiceExpirationDate) => {
            if (invoiceExpirationDate) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
                    .test(
                        "empty-check",
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_EXPIRATION.ERROR_MULTIPLE_SETTINGS" />,
                        (text) => !text || text.length === 0
                    );
            } else {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />);
            }
        }),
        invoiceExpirationDate: yup.string().when("invoiceExpirationDays", (invoiceExpirationDays) => {
            if (invoiceExpirationDays) {
                return yup
                    .string()
                    .nullable(true)
                    .test(
                        "empty-check",
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_EXPIRATION.ERROR_MULTIPLE_SETTINGS" />,
                        (text) => !text || text.length === 0
                    );
            } else {
                return yup.string().nullable(true);
            }
        }),
        invoiceFee: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
            .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
            .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
        adminFee: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
            .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
            .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    },
    ["invoiceExpirationDays", "invoiceExpirationDate"]
);

function BillingTemplatePageAdd({
    addProfileBillingTemplate,
    clearBillingTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { billingTemplate, isLoading } = useSelector((state) => state.billingTemplates);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        return () => {
            clearBillingTemplateState();
            clearErrorState();
        };
    }, [clearBillingTemplateState, clearErrorState]);

    const onFormSubmit = (values) => {
        var billingTemplateData = {
            name: values.name,
            isDefault: values.isDefault,
            organisationName: values.organisationName,
            organisationNumber: values.organisationNumber,
            vatNumber: values.vatNumber,
            invoiceExpirationDays: values.invoiceExpirationDays,
            invoiceFee: values.invoiceFee,
            adminFee: values.adminFee,
        };

        if (values.invoiceExpirationDate) {
            billingTemplateData.invoiceExpirationDate = moment(values.invoiceExpirationDate).format("YYYY-MM-DD");
        }

        addProfileBillingTemplate(profileId, billingTemplateData, () => {
            setRedirect(true);
        });
    };

    if (redirect && billingTemplate) {
        return <Redirect to={`/billing-templates/${billingTemplate.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="BILLING_TEMPLATE.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="PROFILE.BILLING_TEMPLATES.SUBTITLE" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <BillingTemplateDetailsForm />

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...billingTemplateActions,
    ...errorActions,
})(BillingTemplatePageAdd);
