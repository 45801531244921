import React from "react";
import { FormattedMessage } from "react-intl";
import { TextCell, ChipCell } from "../../components/tables/MaterialTableCells";

export const REPORT_TYPE_TRANSACTIONS = "transactions";
export const REPORT_TYPE_GROUP_ATTENDEES_COUNT = "group_attendees_count";
export const REPORT_TYPE_GROUP_ATTENDEES = "group_attendees";
export const REPORT_TYPE_GROUP_OCCASION_ATTENDEES = "group_occasion_attendees";
export const REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS = "customer_appointment_bookings";
export const REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED = "customer_appointment_bookings_aggregated";
export const REPORT_TYPE_RESOURCES = "resources";
export const REPORT_TYPE_RESOURCE_APPOINTMENT_BOOKINGS = "resource_appointment_bookings";
export const REPORT_TYPE_SUBSCRIPTIONS = "subscriptions";

export const REPORT_STATUS_PENDING = "pending";
export const REPORT_STATUS_STARTED = "started";
export const REPORT_STATUS_FINISHED = "finished";
export const REPORT_STATUS_ERROR = "error";
export const REPORT_STATUS_DELETED = "deleted";

export function getReportNameFromType(type, intl) {
    if (type === REPORT_TYPE_TRANSACTIONS) {
        return intl.formatMessage({ id: "REPORT.TYPE.TRANSACTIONS" });
    } else if (type === REPORT_TYPE_GROUP_ATTENDEES_COUNT) {
        return intl.formatMessage({ id: "REPORT.TYPE.GROUPS_ATTENDEES_COUNT" });
    } else if (type === REPORT_TYPE_GROUP_ATTENDEES) {
        return intl.formatMessage({ id: "REPORT.TYPE.GROUPS_ATTENDEES" });
    } else if (type === REPORT_TYPE_GROUP_OCCASION_ATTENDEES) {
        return intl.formatMessage({ id: "REPORT.TYPE.GROUP_OCCASION_ATTENDEES" });
    } else if (type === REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS) {
        return intl.formatMessage({ id: "REPORT.TYPE.CUSTOMER_APPOINTMENT_BOOKINGS" });
    } else if (type === REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED) {
        return intl.formatMessage({ id: "REPORT.TYPE.CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED" });
    } else if (type === REPORT_TYPE_RESOURCES) {
        return intl.formatMessage({ id: "REPORT.TYPE.RESOURCES" });
    } else if (type === REPORT_TYPE_RESOURCE_APPOINTMENT_BOOKINGS) {
        return intl.formatMessage({ id: "REPORT.TYPE.RESOURCE_APPOINTMENT_BOOKINGS" });
    } else if (type === REPORT_TYPE_SUBSCRIPTIONS) {
        return intl.formatMessage({ id: "REPORT.TYPE.SUBSCRIPTIONS" });
    }

    return type;
}

export function getReportInfoFromType(type, intl) {
    if (type === REPORT_TYPE_TRANSACTIONS) {
        return intl.formatMessage({ id: "REPORT.TYPE.TRANSACTIONS.INFO" });
    } else if (type === REPORT_TYPE_GROUP_ATTENDEES_COUNT) {
        return intl.formatMessage({ id: "REPORT.TYPE.GROUPS_ATTENDEES_COUNT.INFO" });
    } else if (type === REPORT_TYPE_GROUP_ATTENDEES) {
        return intl.formatMessage({ id: "REPORT.TYPE.GROUPS_ATTENDEES.INFO" });
    } else if (type === REPORT_TYPE_GROUP_OCCASION_ATTENDEES) {
        return intl.formatMessage({ id: "REPORT.TYPE.GROUP_OCCASION_ATTENDEES.INFO" });
    } else if (type === REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS) {
        return intl.formatMessage({ id: "REPORT.TYPE.CUSTOMER_APPOINTMENT_BOOKINGS.INFO" });
    } else if (type === REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED) {
        return intl.formatMessage({ id: "REPORT.TYPE.CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED.INFO" });
    } else if (type === REPORT_TYPE_RESOURCES) {
        return intl.formatMessage({ id: "REPORT.TYPE.RESOURCES.INFO" });
    } else if (type === REPORT_TYPE_RESOURCE_APPOINTMENT_BOOKINGS) {
        return intl.formatMessage({ id: "REPORT.TYPE.RESOURCE_APPOINTMENT_BOOKINGS.INFO" });
    } else if (type === REPORT_TYPE_SUBSCRIPTIONS) {
        return intl.formatMessage({ id: "REPORT.TYPE.SUBSCRIPTIONS.INFO" });
    }

    return type;
}

export function getReportStatusCell(status) {
    if (status === REPORT_STATUS_PENDING) {
        return ChipCell("info", <FormattedMessage id="REPORT.STATUS.PENDING" />);
    } else if (status === REPORT_STATUS_STARTED) {
        return ChipCell("warning", <FormattedMessage id="REPORT.STATUS.STARTED" />);
    } else if (status === REPORT_STATUS_FINISHED) {
        return ChipCell("success", <FormattedMessage id="REPORT.STATUS.FINISHED" />);
    } else if (status === REPORT_STATUS_ERROR) {
        return ChipCell("error", <FormattedMessage id="REPORT.STATUS.ERROR" />);
    } else if (status === REPORT_STATUS_DELETED) {
        return ChipCell("error", <FormattedMessage id="REPORT.STATUS.DELETED" />);
    }
    return TextCell();
}

export function getReportCopyUrl(reportId) {
    return `/reports/create?copyReportId=${reportId}`;
}
